import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db, auth } from './firebase';
import {
  Container,
  Button,
  VStack,
  Heading,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  Alert,
  AlertIcon,
  HStack,
  Tfoot
} from '@chakra-ui/react';

function CartridgePage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [cartridge, setCartridge] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCartridge = async () => {
      try {
        const user = auth.currentUser;
        if (!user) {
          setError("You must be logged in to view this cartridge.");
          setLoading(false);
          return;
        }

        const cartridgeRef = doc(db, 'users', user.uid, 'cartridges', id);
        const cartridgeDoc = await getDoc(cartridgeRef);

        if (cartridgeDoc.exists()) {
          setCartridge({ id: cartridgeDoc.id, ...cartridgeDoc.data() });
        } else {
          setError("Cartridge not found.");
        }
      } catch (error) {
        console.error("Error fetching cartridge: ", error);
        setError("An error occurred while fetching the cartridge.");
      }
      setLoading(false);
    };
    fetchCartridge();
  }, [id]);

  if (loading) return <Container maxW="container.xl" mt={4}><Text>Loading...</Text></Container>;

  if (error) {
    return (
      <Container maxW="container.xl" mt={4}>
        <Alert status="error">
          <AlertIcon />
          {error}
        </Alert>
        <Button onClick={() => navigate('/cartridge-calculator')} mt={4}>Return to Calculator</Button>
      </Container>
    );
  }

  if (!cartridge) return <Container maxW="container.xl" mt={4}><Text>No cartridge found!</Text></Container>;

  return (
    <Container maxW="container.xl" mt={4}>
      <HStack justifyContent="space-between" mb={4}>
        <Button colorScheme="gray" onClick={() => navigate('/cartridge-calculator')}>
          Back to Calculator
        </Button>
        <Button colorScheme="blue" onClick={() => navigate(`/cartridge-calculator?edit=${id}`)}>
          Edit Cartridge
        </Button>
      </HStack>
      <VStack align="stretch" spacing={4} mb={3}>
        <Box>
          <Heading color="brand.bodyFont" as="h1">{cartridge.name}</Heading>
          <Text>Cartridge Capacity: {cartridge.cartridgeCapacity}g</Text>
          <Text>Total Cartridges: {cartridge.totalCartridges}</Text>
        </Box>
      </VStack>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th color="brand.bodyFont">Ingredient Name</Th>
            <Th color="brand.bodyFont">Ratio (%)</Th>
            <Th color="brand.bodyFont">Quantity (g)</Th>
            <Th color="brand.bodyFont">Cost per gram ($)</Th>
            <Th color="brand.bodyFont">Total Cost ($)</Th>
          </Tr>
        </Thead>
        <Tbody>
          {cartridge.ingredients.map((ingredient, idx) => {
            const totalVolume = cartridge.cartridgeCapacity * cartridge.totalCartridges;
            const quantity = ((parseFloat(ingredient.ratio) / 100) * totalVolume).toFixed(3);
            const totalCost = (parseFloat(ingredient.costPerGram) * parseFloat(quantity)).toFixed(2);
            return (
              <Tr key={idx}>
                <Td>{ingredient.name}</Td>
                <Td>{ingredient.ratio}%</Td>
                <Td>{quantity}g</Td>
                <Td>${ingredient.costPerGram}</Td>
                <Td>${totalCost}</Td>
              </Tr>
            );
          })}
        </Tbody>
        <Tfoot>
          <Tr fontWeight="bold">
            <Th color="brand.bodyFont">Totals</Th>
            <Td>{cartridge.ingredients.reduce((sum, ing) => sum + parseFloat(ing.ratio), 0).toFixed(2)}%</Td>
            <Td>{(cartridge.cartridgeCapacity * cartridge.totalCartridges).toFixed(3)}g</Td>
            <Td>-</Td>
            <Td>${cartridge.ingredients.reduce((sum, ing) => sum + parseFloat(ing.costPerGram) * parseFloat(ing.quantity), 0).toFixed(2)}</Td>
          </Tr>
        </Tfoot>
      </Table>
      <Table variant="simple" mt={4}>
        <Tbody>
          <Tr>
            <Th color="brand.bodyFont">Hardware Cost</Th>
            <Td>${cartridge.hardwareCost}</Td>
          </Tr>
          <Tr>
            <Th color="brand.bodyFont">Labor Cost</Th>
            <Td>${cartridge.laborCost}</Td>
          </Tr>
          <Tr>
            <Th color="brand.bodyFont">Packaging Cost</Th>
            <Td>${cartridge.packagingCost}</Td>
          </Tr>
          <Tr fontWeight="bold">
            <Th color="brand.bodyFont">Total Cost</Th>
            <Td>${cartridge.totalCost}</Td>
          </Tr>
          <Tr fontWeight="bold">
            <Th color="brand.bodyFont">Cost per Cartridge</Th>
            <Td>${(cartridge.totalCost / cartridge.totalCartridges).toFixed(2)}</Td>
          </Tr>
        </Tbody>
      </Table>
      <Button onClick={() => window.print()} colorScheme="blue" mt={4}>Print</Button>
    </Container>
  );
}

export default CartridgePage;
