import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  Box, Heading, VStack, HStack, FormControl, FormLabel, Input, NumberInput, NumberInputField,
  Table, Thead, Tbody, Tr, Th, Td, Button, Flex, Select, Tfoot, Checkbox, IconButton, Text, useColorModeValue
} from '@chakra-ui/react';
import { db, auth } from './firebase';
import { collection, getDocs, getDoc, query, orderBy, doc, setDoc, deleteDoc, updateDoc } from 'firebase/firestore';
import { DeleteIcon } from '@chakra-ui/icons';



function CartridgeCalculator({ user }) {
  const [availableIngredients, setAvailableIngredients] = useState([]);
  const [selectedIngredients, setSelectedIngredients] = useState([]);
  const [cartridgeCapacity, setCartridgeCapacity] = useState(1);
  const [totalCartridges, setTotalCartridges] = useState(1);
  const [formulaName, setFormulaName] = useState('');
  const [hardwareCost, setHardwareCost] = useState('0');
  const [laborCost, setLaborCost] = useState('0');
  const [packagingCost, setPackagingCost] = useState('0');
  const [totalCost, setTotalCost] = useState(0);
  const [selectedIngredientId, setSelectedIngredientId] = useState('');
  const [isPublic, setIsPublic] = useState(false);
  const navigate = useNavigate();
  const [savedCartridges, setSavedCartridges] = useState([]);
  const [editingCartridgeId, setEditingCartridgeId] = useState(null);
  const [costPerGram, setCostPerGram] = useState({});
  const bgColor = useColorModeValue('#272D39');
  const textColor = useColorModeValue('white');

  useEffect(() => {
  const fetchSavedCartridges = async () => {
    if (!user) return;

    try {
      const userCartridgesRef = collection(db, 'users', user.uid, 'cartridges');
      const cartridgesSnapshot = await getDocs(userCartridgesRef);

      const userCartridges = cartridgesSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      setSavedCartridges(userCartridges);
    } catch (error) {
      console.error('Error fetching saved cartridges:', error);
    }
  };

    fetchSavedCartridges();
    }, [user]);
    const fetchCartridge = async (cartridgeId) => {
    try {
      const cartridgeRef = doc(db, 'users', user.uid, 'cartridges', cartridgeId);
      const cartridgeDoc = await getDoc(cartridgeRef);
      if (cartridgeDoc.exists()) {
        const cartridgeData = cartridgeDoc.data();
        setFormulaName(cartridgeData.name);
        setCartridgeCapacity(cartridgeData.cartridgeCapacity);
        setTotalCartridges(cartridgeData.totalCartridges);
        setSelectedIngredients(cartridgeData.ingredients);
        setHardwareCost(cartridgeData.hardwareCost);
        setLaborCost(cartridgeData.laborCost);
        setPackagingCost(cartridgeData.packagingCost);
        setIsPublic(cartridgeData.isPublic || false);
      }
    } catch (error) {
      console.error("Error fetching cartridge: ", error);
    }
  };

  useEffect(() => {
  const queryParams = new URLSearchParams(window.location.search);
  const editId = queryParams.get('edit');
  if (editId) {
    setEditingCartridgeId(editId);
    fetchCartridge(editId);
    }
  }, []);

  const handleDeleteIngredient = (id) => {
    setSelectedIngredients(prevIngredients => prevIngredients.filter(ing => ing.id !== id));
  };

  const handleDeleteCartridge = async (cartridgeId) => {
    try {
      await deleteDoc(doc(db, 'users', user.uid, 'cartridges', cartridgeId));
      setSavedCartridges((prevCartridges) => prevCartridges.filter(cartridge => cartridge.id !== cartridgeId));
      console.log("Cartridge deleted successfully!");
    } catch (error) {
      console.error("Error deleting cartridge: ", error);
    }
  };

  const handleSaveCartridge = async () => {
    if (!user) {
      navigate('/login');
      return;
    }

    const totalVolume = cartridgeCapacity * totalCartridges;
    const ingredientsWithQuantities = selectedIngredients.map(ingredient => ({
      ...ingredient,
      quantity: calculateIngredientQuantity(ingredient),
      cost: calculateIngredientCost(ingredient)
    }));
    const cartridge = {
      name: formulaName,
      ingredients: ingredientsWithQuantities,
      cartridgeCapacity,
      totalCartridges,
      hardwareCost,
      laborCost,
      packagingCost,
      totalCost,
      createdBy: user.uid,
      isPublic,
    };

    try {
      let cartridgeRef;
      if (editingCartridgeId) {
        cartridgeRef = doc(db, 'users', user.uid, 'cartridges', editingCartridgeId);
        await updateDoc(cartridgeRef, cartridge);
      } else {
        cartridgeRef = doc(collection(db, 'users', user.uid, 'cartridges'));
        await setDoc(cartridgeRef, cartridge);
      }
      console.log("Cartridge saved successfully!");
      navigate(`/cartridge/${cartridgeRef.id}`);
    } catch (error) {
      console.error("Error saving cartridge: ", error);
    }
  };

  useEffect(() => {
    const fetchIngredients = async () => {
      try {
        const ingredientsQuery = query(collection(db, 'ingredients'), orderBy('id', 'asc'));
        const querySnapshot = await getDocs(ingredientsQuery);
        const ingredientList = querySnapshot.docs.map(doc => ({
          id: doc.id,  // Use Firestore document ID
          name: doc.data().name,
          prices: doc.data().prices,
        }));
        console.log('Fetched ingredients:', ingredientList);
        setAvailableIngredients(ingredientList);
      } catch (error) {
        console.error("Error fetching ingredients: ", error);
      }
    };

    fetchIngredients();
  }, []);

  const handleAddIngredient = () => {
    console.log('Selected Ingredient ID:', selectedIngredientId);
    console.log('Available Ingredients:', availableIngredients);
    const ingredientToAdd = availableIngredients.find(ing => ing.id === selectedIngredientId);
    console.log('Ingredient to Add:', ingredientToAdd);
    if (ingredientToAdd && !selectedIngredients.some(ing => ing.id === ingredientToAdd.id)) {
      console.log('Adding ingredient:', ingredientToAdd);
      setSelectedIngredients(prevIngredients => {
        console.log('Previous Ingredients:', prevIngredients);
        return [...prevIngredients, { ...ingredientToAdd, ratio: 0, costPerGram: 0 }];
      });
      setSelectedIngredientId('');
    }
  };


  const handleRatioChange = (id, value, field) => {
    console.log('handleRatioChange:', { id, value, field });
    setSelectedIngredients(prevIngredients =>
      prevIngredients.map(ing =>
        ing.id === id ? { ...ing, [field]: value } : ing
      )
    );
    // Force a re-render to update calculated values
    setTotalCost(calculateTotalCost());
  };



  const calculateIngredientCost = (ingredient) => {
    if (!ingredient || !ingredient.ratio || !ingredient.costPerGram) return '0.00';
    const ratio = parseFloat(ingredient.ratio) || 0;
    const costPerGram = parseFloat(ingredient.costPerGram) || 0;
    const quantity = (ratio / 100) * cartridgeCapacity * totalCartridges;
    return (quantity * costPerGram).toFixed(2);
  };

  const calculateIngredientQuantity = (ingredient) => {
    if (!ingredient || !ingredient.ratio) return '0.000';
    const ratio = parseFloat(ingredient.ratio) || 0;
    const totalVolume = cartridgeCapacity * totalCartridges;
    return ((ratio / 100) * totalVolume).toFixed(3);
  };

  const calculateTotalCost = () => {
    const ingredientsCost = selectedIngredients.reduce((sum, ing) => {
      const cost = parseFloat(calculateIngredientCost(ing)) || 0;
      return sum + cost;
    }, 0);
    const additionalCosts = (parseFloat(hardwareCost) + parseFloat(laborCost) + parseFloat(packagingCost)) * totalCartridges;
    return ingredientsCost + additionalCosts;
  };

  useEffect(() => {
    const newTotalCost = calculateTotalCost();
    setTotalCost(newTotalCost);
  }, [selectedIngredients, cartridgeCapacity, totalCartridges, hardwareCost, laborCost, packagingCost]);

  useEffect(() => {
    console.log('Selected Ingredients Updated:', selectedIngredients);
  }, [selectedIngredients]);

  return (
    <Box p={0}>
      <Heading as="h2" size="md" mb={2} pl={0} pt={1} color="brand.bodyFont">Cartridge Cost Calculator</Heading>

      <VStack spacing={6} align="stretch">
        <HStack spacing={4}>
          <FormControl>
            <FormLabel>Formula Name</FormLabel>
            <Input size='sm'
            color="brand.bodyFont" value={formulaName} onChange={(e) => setFormulaName(e.target.value)} />
          </FormControl>
          <FormControl>
            <FormLabel>Cartridge Capacity (g)</FormLabel>
            <NumberInput
              size='sm'
              color="brand.bodyFont"
              value={cartridgeCapacity}
              onChange={(value) => setCartridgeCapacity(parseFloat(value) || 0)}
              min={0.1}
              step={0.1}
              precision={2}
            >
              <NumberInputField />
            </NumberInput>
          </FormControl>
          <FormControl>
            <FormLabel>Total Cartridges</FormLabel>
            <NumberInput size='sm'
            color="brand.bodyFont" value={totalCartridges} onChange={(value) => setTotalCartridges(parseInt(value) || 1)} min={1}>
              <NumberInputField />
            </NumberInput>
          </FormControl>
        </HStack>

        <Box overflowX="auto">
        <Table size='sm' variant="unstyled" width="100%" colorScheme='whiteAlpha'>
          <Thead>
            <Tr>
              <Th color="brand.tableHeader" borderColor="gray.600" borderWidth="0.25px">Ingredient</Th>
              <Th color="brand.tableHeader" borderColor="gray.600" borderWidth="0.25px">Ratio (%)</Th>
              <Th color="brand.tableHeader" borderColor="gray.600" borderWidth="0.25px">Total Quantity (g)</Th>
              <Th color="brand.tableHeader" borderColor="gray.600" borderWidth="0.25px">Cost per gram ($)</Th>
              <Th color="brand.tableHeader" borderColor="gray.600" borderWidth="0.25px">Calculated Cost ($)</Th>
            </Tr>
          </Thead>
          <Tbody>
            {selectedIngredients.map((ingredient) => (
              <Tr key={ingredient.id}>

                <Td borderColor="gray.600" borderWidth="0.25px">
                <Flex justify="space-between" align="center">
                  <Text>{ingredient.name}</Text>
                  <IconButton
                    icon={<DeleteIcon />}
                    size="sm"
                    colorScheme="red"
                    variant="ghost"
                    onClick={() => handleDeleteIngredient(ingredient.id)}
                    aria-label={`Delete ${ingredient.name}`}
                  />
                  </Flex>
                  </Td>
                <Td verticalAlign="middle" borderColor="gray.600" borderWidth="0.25px" height="40px">
                  <Flex align="center" height="100%">
                    <NumberInput
                      size='xs'
                      value={ingredient.ratio}
                      onChange={(valueString) => handleRatioChange(ingredient.id, valueString, 'ratio')}
                      min={0}
                      max={100}
                      precision={2}
                      step={0.01}
                      width="100%"
                    >
                      <NumberInputField />
                    </NumberInput>
                  </Flex>
                </Td>
                <Td borderColor="gray.600" borderWidth="0.25px">{calculateIngredientQuantity(ingredient)}</Td>
                <Td verticalAlign="middle" borderColor="gray.600" borderWidth="0.25px" height="40px">
                  <Flex align="center" height="100%">
                    <NumberInput
                      size='xs'
                      value={ingredient.costPerGram || ''}
                      onChange={(valueString) => handleRatioChange(ingredient.id, valueString, 'costPerGram')}
                      min={0}
                      max={100}
                      precision={2}
                      step={0.01}
                      width="100%"
                    >
                      <NumberInputField
                        value={ingredient.costPerGram}
                        onChange={(valueString) => {
                          console.log('Raw input:', valueString);
                          // Allow the decimal point and digits
                          if (/^\d*\.?\d*$/.test(valueString)) {
                            handleRatioChange(ingredient.id, valueString, 'costPerGram');
                          }
                        }}
                      />
                    </NumberInput>
                    </Flex>
                  </Td>
                  <Td borderColor="gray.600" borderWidth="0.25px">${calculateIngredientCost(ingredient)}</Td>
                </Tr>
              ))}
              <Tr>
                <Td borderColor="gray.600" borderWidth="0.25px">
                  <Select
                    value={selectedIngredientId}
                    size='sm'
                    color="brand.bodyFont"
                    bg={bgColor}
                    onChange={(e) => setSelectedIngredientId(e.target.value)}
                    placeholder="Select ingredient"
                    _hover={{
                      borderColor: 'brand.500',
                    }}
                    sx={{
                      '& option': {
                        bg: bgColor,
                        color: textColor,
                      },
                    }}
                  >
                    {availableIngredients.map(ing => (
                      <option key={ing.id} value={ing.id}>{ing.name}</option>
                    ))}
                  </Select>
                </Td>
                <Td borderColor="gray.600" borderWidth="0.25px" colSpan={3}>
                  <Button size='sm' onClick={handleAddIngredient} isDisabled={!selectedIngredientId}>
                    Add Ingredient
                  </Button>
                </Td>
              </Tr>
            </Tbody>
            <Tfoot>
              <Tr fontWeight="bold">
                <Td borderColor="gray.600" borderWidth="0.25px">Totals</Td>
                <Td borderColor="gray.600" borderWidth="0.25px">
                  {selectedIngredients.reduce((sum, ing) => sum + (parseFloat(ing.ratio) || 0), 0).toFixed(2)}%
                </Td>
                <Td borderColor="gray.600" borderWidth="0.25px">
                  {selectedIngredients.reduce((sum, ing) => sum + parseFloat(calculateIngredientQuantity(ing)), 0).toFixed(2)}g
                </Td>
                <Td>-</Td>
                <Td borderColor="gray.600" borderWidth="0.25px">
                  ${selectedIngredients.reduce((sum, ing) => sum + parseFloat(calculateIngredientCost(ing)), 0).toFixed(2)}
                </Td>
              </Tr>
            </Tfoot>
          </Table>

          <Table size='sm' variant="unstyled" width="100%" colorScheme='whiteAlpha' tableLayout="fixed" mt={4}>
            <Tbody>
              <Tr>
                <Td borderColor="gray.600" borderWidth="0.25px">Hardware Cost (per cartridge)</Td>
                <Td verticalAlign="middle" borderColor="gray.600" borderWidth="0.25px" height="40px">
                  <Flex align="center" height="100%">
                    <NumberInput
                      size='xs'
                      value={hardwareCost}
                      onChange={(valueString) => {
                        if (/^\d*\.?\d*$/.test(valueString)) {
                          setHardwareCost(valueString);
                        }
                      }}
                      min={0}
                      step={0.01}
                      precision={2}
                      width="100%"
                    >
                      <NumberInputField />
                    </NumberInput>
                  </Flex>
                </Td>
              </Tr>
              <Tr>
                <Td borderColor="gray.600" borderWidth="0.25px">Labor Cost (per cartridge)</Td>
                <Td verticalAlign="middle" borderColor="gray.600" borderWidth="0.25px" height="40px">
                  <Flex align="center" height="100%">
                    <NumberInput
                      size='xs'
                      value={laborCost}
                      onChange={(valueString) => {
                        if (/^\d*\.?\d*$/.test(valueString)) {
                          setLaborCost(valueString);
                        }
                      }}
                      min={0}
                      step={0.01}
                      precision={2}
                      width="100%"
                    >
                      <NumberInputField />
                    </NumberInput>
                  </Flex>
                </Td>
              </Tr>
              <Tr>
                <Td borderColor="gray.600" borderWidth="0.25px">Packaging Cost (per cartridge)</Td>
                <Td verticalAlign="middle" borderColor="gray.600" borderWidth="0.25px" height="40px">
                  <Flex align="center" height="100%">
                    <NumberInput
                      size='xs'
                      value={packagingCost}
                      onChange={(valueString) => {
                        if (/^\d*\.?\d*$/.test(valueString)) {
                          setPackagingCost(valueString);
                        }
                      }}
                      min={0}
                      step={0.01}
                      precision={2}
                      width="100%"
                    >
                      <NumberInputField />
                    </NumberInput>
                  </Flex>
                </Td>
              </Tr>
            </Tbody>
          </Table>

          <Table size='sm' variant="unstyled" width="100%" colorScheme='whiteAlpha' tableLayout="fixed" mt={4}>
            <Tbody>
              <Tr fontWeight="bold" bg="gray.800">
                <Td borderColor="gray.600" borderWidth="0.25px" color='white'>Total Cost for {totalCartridges} Cartridges</Td>
                <Td borderColor="gray.600" borderWidth="0.25px" color='white' textAlign="right">${totalCost.toFixed(2)}</Td>
              </Tr>
              <Tr fontWeight="bold" bg="gray.800">
                <Td borderColor="gray.600" borderWidth="0.25px" color='white'>Cost per Cartridge</Td>
                <Td borderColor="gray.600" borderWidth="0.25px" color='white' textAlign="right">${(totalCost / totalCartridges).toFixed(2)}</Td>
              </Tr>
            </Tbody>
          </Table>
          <Flex mt={4} alignItems="center" gap={4}>
            <Button size='sm' onClick={handleSaveCartridge} colorScheme="blue" mt={4}>
              {editingCartridgeId ? 'Update Cartridge' : 'Save Cartridge'}
            </Button>
            <Checkbox
              isChecked={isPublic}
              onChange={(e) => setIsPublic(e.target.checked)}
              ml={4}
              pt={3}
            >
              Make Public
            </Checkbox>
            <Button size='sm' onClick={() => window.print()} colorScheme="blue" mt={4}>Print</Button>
          </Flex>
        </Box>
        {/* Saved Cartridges Section */}
        {savedCartridges.length > 0 && (
          <Box>
            <Heading as="h3" size="md" mb={4} color="brand.bodyFont">Saved Cartridges</Heading>
            <VStack spacing={4} align="stretch">
              {savedCartridges.map((cartridge, index) => (
                <Box key={index} borderWidth={1} borderRadius="lg" p={4}>
                  <Heading as="h4" size="md" color="brand.bodyFont">
                    <Link to={`/cartridge/${cartridge.id}`}>
                      {cartridge.name} (Capacity: {cartridge.cartridgeCapacity}g, Total: {cartridge.totalCartridges})
                    </Link>
                  </Heading>
                  <Button colorScheme="red" size="sm" mt={2} onClick={() => handleDeleteCartridge(cartridge.id)}>
                    Delete
                  </Button>
                  <Table variant="simple" size="sm" mt={2}>
                    <Thead>
                      <Tr>
                        <Th color="brand.tableHeader">Ingredient Name</Th>
                        <Th color="brand.tableHeader">Ratio (%)</Th>
                        <Th color="brand.tableHeader">Quantity (g)</Th>
                        <Th color="brand.tableHeader">Cost ($)</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {cartridge.ingredients.map((ingredient, idx) => (
                        <Tr key={idx}>
                          <Td>{ingredient.name}</Td>
                          <Td>{ingredient.ratio}%</Td>
                          <Td>{ingredient.quantity}g</Td>
                          <Td>${ingredient.cost}</Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                  <Table variant="simple" size="sm" mt={2}>
                    <Tbody>
                      <Tr>
                        <Th color="brand.tableHeader">Hardware Cost</Th>
                        <Td>${cartridge.hardwareCost}</Td>
                      </Tr>
                      <Tr>
                        <Th color="brand.tableHeader">Labor Cost</Th>
                        <Td>${cartridge.laborCost}</Td>
                      </Tr>
                      <Tr>
                        <Th color="brand.tableHeader">Packaging Cost</Th>
                        <Td>${cartridge.packagingCost}</Td>
                      </Tr>
                      <Tr fontWeight="bold">
                        <Th color="brand.tableHeader">Total Cost</Th>
                        <Td>${cartridge.totalCost}</Td>
                      </Tr>
                      <Tr fontWeight="bold">
                        <Th color="brand.tableHeader">Cost per Cartridge</Th>
                        <Td>${(cartridge.totalCost / cartridge.totalCartridges).toFixed(2)}</Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </Box>
              ))}
            </VStack>
          </Box>
        )}

      </VStack>
    </Box>



  );
}

export default CartridgeCalculator;
